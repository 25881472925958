/*!
 * SITE main application
 * @copyright Copyright 2019
 *
 * Tapio Löytty, <tapsa@orange-media.fi>
 * Web: www.orange-media.fi
 * Goran Ilic, <ja@ich-mach-das.at>
 * Web: www.ich-mach-das.at
 */

// Core
import { create, boot } from 'core';
import * as utils from 'utils';
//

//import ajax from 'plugins/ajax';
import doubleTapMenu from 'plugins/doubleTapMenu';
import initializeLightGallery from 'plugins/initializeLightGallery';
import hideNotificationAlerts from 'plugins/hideNotificationAlerts';
import heroSlider from 'plugins/initializeSwiperSlider';
import processForm from 'plugins/formSubmitHandler';
import imgLazyLoad from 'plugins/imgLazyLoad';
import loadOSMaps from 'plugins/loadOSMaps';
import scrollToTop from 'plugins/scrollToTop';
import scrollToHash from 'plugins/scrollToHash';
import stickyElement from 'plugins/stickyElement';
import toggleHamburgerMenu from 'plugins/toggleHamburgerMenu';

// Import Vendor
//import AOS from 'aos';
import 'vendor/svgxuse';
import flatpickr from 'flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js';
import { German } from 'flatpickr/dist/l10n/de.js';

//=============================================================
// Config
//=============================================================
// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module?.hot) {
    module.hot.accept();
}
const config = {
    google: {
        maps: {
            api_key: '',
        },
    },
};

//=============================================================
// Options
//=============================================================

const defaultOptions = {
    rootUrl: './',
};

//=============================================================
// Bind API
//=============================================================

const API = create(config);

//API.use(ajax);
API.use(doubleTapMenu);
API.use(initializeLightGallery);
API.use(hideNotificationAlerts);
API.use(heroSlider);
API.use(imgLazyLoad);
API.use(loadOSMaps);
API.use(processForm);
API.use(scrollToHash);
API.use(scrollToTop);
API.use(stickyElement);
API.use(toggleHamburgerMenu);

API.page = document.querySelector('body').dataset;

API.init = (options) => {
    // Set runtime options
    API.options = { ...defaultOptions, ...options };

    API.tasks.init.run();

    // Animations
    //AOS.init({
    //    once: true,
    //});

    // Init the slider function
    let hero = document.querySelector('.swiper-init');
    if (hero) {
        const slider = API.heroSlider('.swiper-init');

        slider.on('slideChangeTransitionStart', function () {
            let heroTitleAll = document.querySelectorAll('.hero--content__box');

            if (heroTitleAll)
                [].forEach.call(heroTitleAll, function (el) {
                    el.classList.remove('slide-in-left');
                });
        });
        slider.on('slideChangeTransitionEnd', function () {
            let heroTitle = document.querySelector(
                '.swiper-slide-active .hero--content__box'
            );
            if (heroTitle) heroTitle.classList.add('slide-in-left');
        });
    }

    // Init gallery plugin
    API.initializeLightGallery('.items--images');
    // Init google map
    const maps = document.querySelectorAll('.map');
    if (maps.length > 0) {
        maps.forEach((item) => {
            let map = API.loadOSMap();
            let mapData = JSON.parse(item.dataset.map);

            map.createMap(item);
            map.addMarkers(mapData);
        });
    }

    // Handle form validation and submit
    const forms = document.querySelectorAll('.form-validate');
    forms.forEach((form) => {
        let errorList = form.querySelector('ul.alerts-validate');

        API.processForm(form, errorList).validateBeforeSubmit();

        if (form.classList.contains('form-ajax'))
            API.processForm(form, errorList).submitWithAjax();
    });

    // Datepicker
    const dateFrom = document.getElementById('date_from');
    const dateTo = document.getElementById('date_to');
    const flatpickrOptions = {
        locale: German,
        enableTime: true,
        time_24hr: true,
        defaultHour: 8,
        dateFormat: 'd-m-Y H:i',
        minDate: 'today',
        plugins: [new rangePlugin({ input: dateTo })],
    };

    if (dateFrom && dateTo) {
        flatpickr(dateFrom, flatpickrOptions);
    }

    const iframeContainer = document.getElementById('page-iframe');
    if (iframeContainer) {
        const pathArray = [
            ['kreuzfahrten', '944'],
            ['hotels_buchen', '1109'],
            ['content', '940'],
            ['reisen', '940'],
            ['charterflug', '940'],
        ];
        for (let n = 0; n < pathArray.length; n += 1) {
            iframeContainer.src.indexOf(pathArray[n][0]) === -1 &&
                (iframeContainer.style.height = pathArray[n][1] + 'px');
        }
    }

    // Scroll back to top page
    API.scrollToTop('#back-top');
    // Init mobile menu icon
    API.toggleHamburgerMenu('.navigation--trigger', '.nav-main');
    API.stickyElement('.section--navigation');

    window.addEventListener('scroll', function () {
        //console.log('scrolling');
    });

    window.addEventListener('resize', function () {
        //console.log('resizing');
    });

    API.tasks.ready.run();

    window.addEventListener('unhandledrejection', function (event) {
        console.warn(
            'WARNING: Unhandled promise rejection. Reason: ' + event.reason,
            event
        );
    });
};

//=============================================================
// Boot API
//=============================================================

boot(API);
